import { Injectable } from '@angular/core';
import {WebserviceService} from "./webservice.service";
import {AuthService} from "./auth.service";

const stub = require('../app/qualidades/interfaces/ChecklistStub.json');
const stubPecas = require('../app/qualidades/interfaces/PecasStub.json');

@Injectable({
  providedIn: 'root'
})
export class QualidadeChecklistService {
  private checklist: IQualidadeChecklist;
  private userFuncao: string;
  private pecas;
  private passos = [
      'montador-qualidades', // 1
      'soldador-qualidades', // 2
      'tecnico-qualidades'  // 3
  ];

  constructor(
      private ws: WebserviceService,
      private user: AuthService
  ) { }

  async getUserPasso() {
    return this.passos.indexOf(await this.user.getUserProfile()) + 1;
  }

  getCurrentChecklist(): IQualidadeChecklist {
    return this.checklist;
  }

  getCurrentChecklistFuncao() {
    return this.checklist[this.getFuncaoUsuario()];
  }

  setFuncaoUsuario(step) {
    this.userFuncao = step;
  }
  getFuncaoUsuario() {
    return this.userFuncao;
  }

  getPecas() {
    return this.pecas;
  }

  getPeca(id) {
    return this.pecas.find(x => x.id == id);
  }

  public async sendChecklist(data) {
    return (await this.ws.doPost('v2/checklist-qualidade', data, true));
  }

  /**
   * Carrega os dados do checklist informado
   */
  public async loadChecklist(codigo: string) {
    this.checklist = (await this.ws.doGet('v2/checklist-qualidade/abrir?codigo=' + codigo, true));
  }

  public async loadPecas() {
    this.pecas = (await this.ws.doGet('v2/checklist-qualidade/lista-pecas', true));
    return this.pecas;
  }

  public async loadAndamento() {
    return (await this.ws.doGet('checklist-qualidade/lista-andamento', false));
  }

  async getFuncaoPerfil() {
    const perfilStepMap = {
      'montador-qualidades': 'montagem',
      'soldador-qualidades': 'soldagem',
      'tecnico-qualidades': 'qualidade'
    };
    const perfil = Object.keys(perfilStepMap);
    const currentPerfil = await this.user.getUserProfile();
    if(perfil.find(x => x == currentPerfil)) {
      return perfilStepMap[currentPerfil];
    }
    return false;
  }
}
