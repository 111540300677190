import {Injectable} from "@angular/core";
import {WebserviceService} from "./webservice.service";
import {AuthService} from "./auth.service";
import {ToastController} from '@ionic/angular';
import {Storage} from "@ionic/storage";

@Injectable({
    providedIn: 'root'
})
export class AplicacaoProdutoService {
    // @ts-ignore
    constructor(private ws: WebserviceService, private storage: Storage) {

    }

    private cachedData: any[];

    async getCachedData() {
        return this.cachedData = await this.storage.get('aplicacao-produto');
    }

    async sincronizar() {
        try {
            if (this.ws.online) {
                await this.getCachedData();

                if(this.cachedData){
                    this.cachedData.forEach((item) => {
                        this.salvar(item);
                    });
                }

                this.storage.remove('aplicacao-produto');

                await this.getCachedData();

                return true;
            } else {
                return false;
            }
        } catch(err) {
            console.log(err);
            return false;
        }
    }

    async salvar(aplicacaoProduto: any) {
        if(!aplicacaoProduto.cliente){
            return false;
        }

        return this.ws.doPost(`produto/aplicacao`, aplicacaoProduto);
    }

    async salvarCache(aplicacaoProduto: any) {
        // Não tem conexão, insere na storage
        let cachedData: any[] = await this.storage.get('aplicacao-produto');
        if (!cachedData) {
            // Cache ainda não existe, apenas setar
            await this.storage.set('aplicacao-produto', [aplicacaoProduto]);
        } else {
            cachedData.push(aplicacaoProduto);
            await this.storage.set('aplicacao-produto', cachedData);
        }
    }
}
