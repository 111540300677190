import { Injectable } from '@angular/core';
import {LoadingController} from "@ionic/angular";
import {WebserviceService} from "./webservice.service";

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoPedidoService {

  constructor(
      private loadingCtrl: LoadingController,
      private ws: WebserviceService
  ) { }

  pedidos: any = [];

  async loadPedidos() {
    try {
      const ret = await this.ws.doGet(
          `pedidos`, false
      );
      if(Array.isArray(ret)) {
        this.pedidos = ret;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async loadPedido(id: number) {
    if(!this.pedidos.length) {
      await this.loadPedidos();
    }
    let pedido = false;
    this.pedidos.forEach((item)=> {
      if(item.id === id) {
        pedido = item;
      }
    });

    return pedido;
  }
}
