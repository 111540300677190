import { Injectable } from '@angular/core';

export class Port {
    id: number;
    name: string;
    country?: string;
    timeZone: number;

    constructor(port) {
        this.id = port.id;
        this.name = port.name;
        this.country = port.country;
        this.timeZone = port.timeZone;
    }
}

export class Country {
    id: number;
    name: string;
    flag?: string;
    get flagUrl(): string {
        return `https://lipis.github.io/flag-icon-css/flags/4x3/${this.flag}.svg`;
    }

    constructor(country) {
        this.id = country.id;
        this.name = country.name;
        this.flag = country.flag;
    }
}
@Injectable({
    providedIn: 'root'
})
export class PortService {
    private countries: Country[] = [new Country({
        id: 0,
        name: 'Brazil',
        flag: 'br',
        ports: [
            new Port({ id: 51, name: 'Areia Branca', timeZone: -180 }),
            new Port({ id: 52, name: 'Navegantes', timeZone: -180 }),
            new Port({ id: 53, name: 'Antonina', timeZone: -180 }),
            new Port({ id: 54, name: 'Santos', timeZone: -180 }),
            new Port({ id: 55, name: 'Paranagua', timeZone: -180 }),
            new Port({ id: 56, name: 'Sao Francisco do Sul', timeZone: -180 }),
            new Port({ id: 57, name: 'Angra dos Reis', timeZone: -180 }),
            new Port({ id: 58, name: 'Rio de Janeiro', timeZone: -180 }),
            new Port({ id: 59, name: 'Vitoria', timeZone: -180 }),
            new Port({ id: 60, name: 'Porto Alegre', timeZone: -180 }),
            new Port({ id: 61, name: 'Itajai', timeZone: -180 }),
            new Port({ id: 62, name: 'Imbituba', timeZone: -180 }),
            new Port({ id: 63, name: 'Pelotas', timeZone: -180 }),
            new Port({ id: 64, name: 'Tubarao', timeZone: -180 }),
            new Port({ id: 65, name: 'Fortaleza', timeZone: -180 }),
            new Port({ id: 66, name: 'Cabedelo', timeZone: -180 }),
            new Port({ id: 67, name: 'Sao Luis', timeZone: -180 }),
            new Port({ id: 68, name: 'Natal', timeZone: -180 }),
            new Port({ id: 69, name: 'Trombetas', timeZone: -240 })
        ]
    }), new Country({
        id: 1,
        name: 'Paraguay',
        flag: 'py',
    }), new Country({
        id: 2,
        name: 'India',
        flag: 'in',
    }), new Country({
        id: 3,
        name: 'Cayman Islands',
        flag: 'ky',
    }), new Country({
        id: 4,
        name: 'Egypt',
        flag: 'eg',
    }), new Country({
        id: 5,
        name: 'Finland',
        flag: 'fi',
    }), new Country({
        id: 6,
        name: 'Germany',
        flag: 'ge',
    }), new Country({
        id: 7,
        name: 'Canada',
        flag: 'ca',
    }), new Country({
        id: 8,
        name: 'Chile',
        flag: 'cl',
    }), new Country({
        id: 9,
        name: 'Argentina',
        flag: 'ar',
    }), new Country({
        id: 10,
        name: 'Curacao',
        flag: 'cw',
    }), new Country({
        id: 11,
        name: 'Sri Lanka',
        flag: 'lk',
    }), new Country({
        id: 12,
        name: 'Madagascar',
        flag: 'mg',
    }), new Country({
        id: 13,
        name: 'Japan',
        flag: 'jp'
    }), new Country({
        id: 14,
        name: 'Ireland',
        flag: 'ie',
        ports: [
            new Port({ id: 21, name: 'Bantry Bay' })
        ]
    }), new Country({
        id: 15,
        name: 'Italy',
        flag: 'it',
        ports: [
            new Port({ id: 22, name: 'Porto Levante' })
        ]
    }), new Country({
        id: 16,
        name: 'Greece',
        flag: 'gr',
        ports: [
            new Port({ id: 23, name: 'Port of Antikyra' })
        ]
    }), new Country({
        id: 17,
        name: 'Malaysia',
        flag: 'my',
        ports: [
            new Port({ id: 38, name: 'Kuantan' }),
            new Port({ id: 24, name: 'Berantai FPSO' })
        ]
    }), new Country({
        id: 18,
        name: 'Spain',
        flag: 'es',
        ports: [
            new Port({ id: 25, name: 'Alicante' }),
            new Port({ id: 45, name: 'Santa Eugenia De Riveira' })
        ]
    }), new Country({
        id: 19,
        name: 'Panama',
        flag: 'pa',
        ports: [
            new Port({ id: 26, name: 'Almirante' })
        ]
    }), new Country({
        id: 20,
        name: 'China',
        flag: 'cn',
        ports: [
            new Port({ id: 39, name: 'Lantian' }),
            new Port({ id: 27, name: 'Canton' }),
            new Port({ id: 42, name: 'Nantong' })
        ]
    }), new Country({
        id: 21,
        name: 'Somalia',
        flag: 'so',
        ports: [
            new Port({ id: 28, name: 'Dante' })
        ]
    }), new Country({
        id: 22,
        name: 'United States',
        flag: 'us',
        ports: [
            new Port({ id: 29, name: 'Davant LA' })
        ]
    }), new Country({
        id: 23,
        name: 'Australia',
        flag: 'au',
        ports: [
            new Port({ id: 30, name: 'Fremantle' })
        ]
    }), new Country({
        id: 24,
        name: 'Philippines',
        flag: 'ph',
        ports: [
            new Port({ id: 31, name: 'General Santos' })
        ]
    }), new Country({
        id: 25,
        name: 'United Kingdom',
        flag: 'gb',
        ports: [
            new Port({ id: 32, name: 'Granton' })
        ]
    }), new Country({
        id: 26,
        name: 'Venezuela',
        flag: 've',
        ports: [
            new Port({ id: 33, name: 'Guanta' })
        ]
    }), new Country({
        id: 27,
        name: 'Indonesia',
        flag: 'id',
        ports: [
            new Port({ id: 35, name: 'Kalimantan' })
        ]
    }), new Country({
        id: 28,
        name: 'Thailand',
        flag: 'th',
        ports: [
            new Port({ id: 36, name: 'Kantang' })
        ]
    }), new Country({
        id: 29,
        name: 'Ecuador',
        flag: 'ec',
        ports: [
            new Port({ id: 40, name: 'Manta' })
        ]
    }), new Country({
        id: 30,
        name: 'France',
        flag: 'fr',
        ports: [
            new Port({ id: 41, name: 'Mantes' })
        ]
    }), new Country({
        id: 31,
        name: 'Russia',
        flag: 'ru',
    }),
    ];

    getCountries(page?: number, size?: number): Country[] {
        return this.countries;
    }

    getCountryByName(name: string): Country {
        return this.countries.find(function(el){
            if(el.name == name) return true;
            return false;
        }) as Country;
    }

    getCountryByFlag(name: string): Country {
        return this.countries.find(function(el){
            if(el.flag == name) return true;
            return false;
        }) as Country;
    }

}
