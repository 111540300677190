import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GrupoAcessoriosService {
  public onChange: Observable<void>;
  private subject = new Subject<void>();

  constructor() {
    this.subject = new Subject();
    this.onChange = this.subject.asObservable();
  }

  public change() {
    this.subject.next();
  }

}
