import { Injectable } from '@angular/core';
import {WebserviceService} from "./webservice.service";
import {AuthService} from "./auth.service";

const stub = require('../app/qualidades/interfaces/ChecklistStub.json');
const stubPecas = require('../app/qualidades/interfaces/PecasStub.json');

@Injectable({
    providedIn: 'root'
})
export class MaquinasQualidadeService {
    private checklist: IMaquinaQualidadeChecklist;
    private userFuncao: string;
    private maquinas;
    private discrepancias;
    private criterios;

    constructor(
        private ws: WebserviceService,
        private user: AuthService
    ) { }

    getCurrentChecklist(): IMaquinaQualidadeChecklist {
        return this.checklist;
    }

    getCurrentChecklistFuncao() {
        return this.checklist[this.getFuncaoUsuario()];
    }

    setFuncaoUsuario(step) {
        this.userFuncao = step;
    }
    getFuncaoUsuario() {
        return this.userFuncao;
    }

    getMaquinas() {
        return this.maquinas;
    }

    getMaquina(id) {
        return this.maquinas.find(x => x.id == id);
    }

    public async getDiscrepancias() {
        this.discrepancias = (await this.ws.doGet('montagem-maquinas/discrepancias', true));
        return this.discrepancias;
    }

    public async getCriterios() {
        this.criterios = (await this.ws.doGet(`montagem-maquinas/discrepancias/criterios`, true));
        console.log(this.criterios);
        return this.criterios;
    }

    public async sendChecklist(data) {
        return (await this.ws.doPost('montagem-maquinas', data, true));
    }

    /**
     * Carrega os dados do checklist informado
     */
    public async loadChecklist(chassi: string) {
        this.checklist = (await this.ws.doGet('montagem-maquinas/abrir?chassi=' + chassi, true));
    }

    public async loadMaquinas() {
        this.maquinas = (await this.ws.doGet('montagem-maquinas/lista-maquinas', true));
        return this.maquinas;
    }

    public async loadAndamento() {
        return (await this.ws.doGet('montagem-maquinas/lista-andamento', false));
    }

    async getFuncaoPerfil() {
        const perfilStepMap = {
            'inspetor-qualidade-1': 'quality_gate_1',
            'inspetor-qualidade': 'quality_gate_2',
            'inspetor-qualidade-3': 'quality_gate_3'
        };
        const perfil = Object.keys(perfilStepMap);
        const currentPerfil = await this.user.getUserProfile();
        if(perfil.find(x => x == currentPerfil)) {
            return perfilStepMap[currentPerfil];
        }
        return false;
    }
}
