import {EventEmitter, Injectable} from '@angular/core';
import {LoadingController} from "@ionic/angular";
import {WebV2Service} from "../services/web-v2.service";

export class Pergunta {
  id: number;
  pergunta: string;
  resposta?: null|boolean;
  // tslint:disable-next-line:variable-name
  plano_de_acao?: null|string;
  opcional?: null|number;

  imagens: [];
}

@Injectable({
  providedIn: 'root'
})
export class ChecklisPosVendasService {
  constructor(
      private loadingCtrl: LoadingController,
      private ws: WebV2Service
  ) {}

  chassi: string;
  preEntrega: any;
  recebimento: any;
  entregaTecnica: any;
  // que ja constam no chassi
  opcionaisExtras: any = [];
  // que podem ser adicionados
  opcionais: any = [];
  passo = 1;

  addPerguntasEvent = new EventEmitter<number>();
  removePerguntasEvent = new EventEmitter<number>();

  async buscaPorChassi(chassi) {
    this.chassi = chassi;

    const loading = await this.loadingCtrl.create({message: 'Carregando dados da checklist...'});
    await loading.present();

    try {
      const ret = await this.ws.doGet(
          `checklist-pos-venda/${chassi}`, false
      );

      await loading.dismiss();
      this.recebimento = ret.checklists.recebimento;
      if(!this.recebimento) {
        // tem que ter pelo menos o recebimento
        return false;
      }
      this.preEntrega = ret.checklists.pre_entrega;
      this.entregaTecnica = ret.checklists.entrega_tecnica;
      this.opcionaisExtras = ret.opcionais_chassi;
      this.opcionais = ret.opcionais;
      this.passo = ret.passo;

    } catch (e) {
      return false;
    }

    return true;
  }

  novoOpcionalExtra(opcional) {
    // emite o evento para os iniciados
    this.addPerguntasEvent.emit(opcional.id);
    // para poder remover depois
    this.opcionaisExtras.push(opcional);
  }

  async removeOpcionalExtra(opcional) {
    // remove dos ativos
    this.opcionaisExtras.forEach((opcionalExtra, index) => {
      if(opcional.id === opcionalExtra.id) {
        this.opcionaisExtras.splice(index, 1);
      }
    });

    this.removePerguntasEvent.emit(opcional.id);
  }

  addSelectedById(id: number| number[]) {
    const addExtra = (toAddId) => {
      this.opcionais.forEach((opcional)=> {
        if(opcional.id === toAddId) {
          this.opcionaisExtras.push(opcional);
        }
      });
    };

    if(Array.isArray(id)) {
      id.forEach((numId) => {
        addExtra(numId);
      });
    } else {
      addExtra(id);
    }
  }

  acessorioSelecionado(id) {
    let selected = false;
    this.opcionaisExtras.forEach((opExtra) => {
      if(opExtra.id == id) {
        selected = true;
      }
    });

    return selected;
  }

  getCurrentTab() {
    console.log('passo '+ this.passo);
    switch (this.passo) {
      case 1:
        return 'checklist-pos-vendas-preencher/tabs/recebimento-tab';
      case 2:
        return 'checklist-pos-vendas-preencher/tabs/pre-entrega-tab';
      case 3:
        return 'checklist-pos-vendas-preencher/tabs/entrega-tecnica-tab';
      default:
        return 'checklist-pos-vendas-preencher/tabs/recebimento-tab';
    }
  }
}
